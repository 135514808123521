import React, { Component } from 'react';
import I18n from "i18n-js";
import NewPassword from '../features/PasswordRecovery/NewPasswordPage';
import { Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import LoginHorizontalPage from "../features/Login/LoginHorizontal";
import {setGlobalState} from "../state";
import {
  LoginPage,
  LandingPage,
  RegisterPartOwnerPage
} from "../pages";

class PublicApp extends Component {
    components = {
        landing: "/landing",
        login: "/log_in",
        log_in_form: "/log_in_form",
        login_horizontal: "/login_horizontal",
        loginWidget: "/login_widget",
        easy_pay: "/pago_facil",
        new_password: "/new_password",
    };

    setLocale = locale => {
      I18n.locale = locale
      I18n.fallbacks = true
    }

    constructor(props) {
        super(props);
        const { data: { locale, marketing_landing_url: marketingLandingUrl, error } } = props;

        setGlobalState({
            marketingLandingUrl: marketingLandingUrl,
            error: error,
        });
        this.setLocale(locale)
    }

    state = {
        redirect: this.components[this.props.data.page],
        props: this.props.data
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={{
                pathname: this.state.redirect,
                props: this.state.props
            }} />
        }
    };

    render() {
        let publicTheme = createTheme({
          overrides: {
            MuiLink: {
              underlineHover: {
                "&:hover": {
                  fontWeight: "bold",
                  textDecoration: "none",
                },
              },
            },
          },
          root: {
            fontFamily: ["Montserrat, sans-serif !important"],
          },
          typography: {
            fontFamily: "Montserrat, sans-serif !important",
            body2: {
              fontSize: "14px",
            },
          },
          palette: {
            common: {
              black: "#444954",
              white: "#FFFFFF",
            },
            background: {
              paper: "#FFFFFF",
              default: "#FFFFFF",
            },
            primary: {
              main: "#505CEF",
              light: "#C8FBE4",
              dark: "#00B470",
              contrastText: "#FFF",
            },
            secondary: {
              light: "#505CEF",
              main: "#505CEF",
              dark: "#2E35A5",
              contrastText: "#FFF",
            },
            error: {
              light: "#E57373",
              main: "#F44336",
              dark: "#F32F2F",
              contrastText: "#FFF",
            },
            text: {
              primary: "#444954",
              secondary: "#5B6E82",
              disabled: "rgba(0, 0, 0, 0.38)",
              hint: "#A6B3C1",
            },
            public: {
              light: "#EFF3FA",
              default_btn: "#EFF3FA",
              smooth_background: "#F7F8FD",
              focus: "#444954",
              public_btn_bg: "#49F2A5",
              public_btn_color: "#09814B",
              public_btn_hover: "#eaeaea",
              public_btn_disabled: "#0ecb68",
            },
          },
        });

        return (
            <div className="PublicApp">
                <ThemeProvider theme={publicTheme}>
                    {this.renderRedirect()}
                    <Switch>
                        <Route exact path="/" render={(props) => <LandingPage {...props} />} />
                        <Route exact path="/log_in" render={(props) => <LandingPage {...props} />} />
                        <Route exact path="/login_widget" component={LoginPage} />
                        <Route exact path="/pago_facil" component={LoginPage} />
                        <Route exact path="/log_in_form" render={(props) => <LoginPage {...props} />} />
                        <Route exact path="/ingresa" render={(props) => <LoginPage {...props} />} />
                        <Route exact path="/sign_up" component={RegisterPartOwnerPage} />
                        <Route exact path="/new_password" component={NewPassword} />
                        <Route exact path="/login_horizontal" render={(props) => <LoginHorizontalPage {...props} />}/>
                    </Switch>
                </ThemeProvider>
            </div>
        );

    }
}
export default PublicApp;
