import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'form', 'modal', 'template', 'successTemplate', 'errorTemplate', 'submitButton', 'submitButtonSpinner', 'submitButtonText' ]

  connect() {
    this.hideSpinner()
    this.formTarget.addEventListener('submit', event => {
      event.preventDefault();
      event.stopPropagation();

      this.disableSubmitButton()
      this.showSpinner()

      const body = new FormData(event.target)
            
      fetch(event.target.action, {
        method: 'POST',
        body: body,
        headers: {
          'Accept': 'application/json',
        }
      }).then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      }).then(body => {
        this.hideSpinner()
        if (body.status === true) {
          this.modalTarget.innerHTML = this.successTemplateTarget.innerHTML;
        } else {
          let errorHTML = this.errorTemplateTarget.innerHTML;
          errorHTML = errorHTML.replace('{{errorTitle}}', body.error_title);
          errorHTML = errorHTML.replace('{{errorMessage}}', body.error_message);
          this.modalTarget.innerHTML = errorHTML;
        }
        $(this.modalTarget).modal()
      }).catch(error => {
        this.hideSpinner()
      })
    });
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true
  }

  showSpinner() {
    this.submitButtonTextTarget.style.display = 'none'
    this.submitButtonSpinnerTarget.style.display = 'flex'
  }

  hideSpinner() {
    this.submitButtonTextTarget.style.display = 'block'
    this.submitButtonSpinnerTarget.style.display = 'none'
  }

  cancelModal(event) {
    $(event.currentTarget).closest('.modal-dialog').remove()
  }
}
